import React from "react";
import { graphql } from "gatsby";
import g from "glamorous";
import { css } from "glamor";
import Layout from "../components/layout";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Social from "../components/Social";
import { Container, Row, Col } from "glamorous-grid";
import Img from "gatsby-image";
import { Link } from "gatsby";
import { FaCheckCircle } from "react-icons/fa";
import { FaUserMd } from "react-icons/fa";
import { IoIosBody } from "react-icons/io";
import { FaPlay } from "react-icons/fa";
import { FaHome } from "react-icons/fa";

export default function Qualifications({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const {
    portrait,
    medailles,
    lvpa,
    keybodylipo,
    keybodyskin,
    portraitbis,
    portraitoperation,
  } = data;

  const Wrapper = g.main({
    maxWidth: `1000px`,
    margin: `0 auto`,
    paddingBottom: `4em`,
  });

  const Title = g.h1({
    textAlign: `center`,
    fontSize: `32px`,
    color: `#3c4d68`,
    maxWidth: `650px`,
    margin: `1.2em auto .6em`,
    fontVariant: `none`,
    fontWeight: `600`,
    letterSpacing: `.01em`,
    lineHeight: `44px`,
    "@media(max-width: 767px)": {
      fontSize: `23px`,
      lineHeight: `31px`,
    },
  });

  const H2 = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `280px`,
      border: `.084em solid #3c4d68`,
      display: `inline-block`,
      width: `687px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Title2 = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `485px`,
      border: `.087em solid #3c4d68`,
      display: `inline-block`,
      width: `470px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Title2bis = g.h2({
    position: `relative`,
    marginBottom: `.5em`,
    "&::after": {
      content: ` `,
      position: `absolute`,
      top: `54%`,
      left: `580px`,
      border: `.087em solid #3c4d68`,
      display: `inline-block`,
      width: `395px`,
    },
    "@media(max-width: 767px)": {
      margin: `.5em 0 .2em`,
      "&::after": {
        content: `none`,
      },
    },
    "@media(max-width: 1100px)": {
      "&::after": {
        content: `none`,
      },
    },
  });

  const Ul = g.ul({
    listStyleType: `none`,
    margin: `.2em 0 .2em`,
    marginBottom: `2em`,
    "@media(max-width: 767px)": {
      margin: `0 0 1em 0 !important`,
    },
  });

  const Li = g.li({
    margin: `.7em 0 .8em`,
    "@media(max-width: 767px)": {
      margin: `.4em 0 .5em`,
      lineHeight: `22px`,
    },
  });

  const cssCheck = css({
    color: `#3c4d68`,
    marginRight: `1em`,
    fontSize: `15px`,
    paddingBottom: `3px`,
    transform: `translateY(3px)`,
    "@media(max-width: 767px)": {
      marginRight: `.5em`,
    },
  });

  const cssPicto1 = css({
    color: `#3c4d68`,
    marginRight: `.15em`,
    fontSize: `46px`,
    marginBottom: `-10px`,
    transform: `translateY(3px)`,
  });

  const cssPicto2 = css({
    color: `#3c4d68`,
    marginRight: `.25em`,
    fontSize: `36px`,
    marginBottom: `-10px`,
    transform: `translateY(3px)`,
  });

  const ccPictoLink = css({
    textAlign: `center`,
    textDecoration: `none`,
    color: `#3c4d68`,
  });

  const Tile = g.div({
    margin: `1em 0 0`,
    backgroundColor: `white`,
    color: `#1f1f1f`,
    border: `1px solid rgba(73,105,126,0.08)`,
    boxShadow: `0 1px 7px rgba(0,0,0,.05)`,
    borderRadius: `4px`,
    transition: `all 0.15s ease-in-out`,
    ":hover": {
      border: `1px solid rgba(73,105,126,0.18)`,
      boxShadow: `0 1px 9px rgba(0,0,0,.2)`,
      transition: `all 0.1s ease-in-out`,
    },
  });

  const H3Block = g.h3({
    textAlign: `center`,
    margin: `.9em 0 .5em`,
    color: `#3c4d68`,
    letterSpacing: `.08em`,
    textDecoration: `none`,
    fontSize: `17px`,
    fontWeight: `bold`,
  });

  const cssLinkH3Block = css({
    textDecoration: `none`,
  });

  const TileText = g.p({
    padding: `0 .8em`,
    lineHeight: `23px`,
    fontSize: `15px`,
  });

  const More = g.div({
    backgroundColor: `#3c4d68`,
    color: `white`,
    fontSize: `13px`,
    padding: `.35em .5em .4em`,
    width: `100px`,
    margin: `1.2em auto 1.8em`,
    textAlign: `center`,
    borderRadius: `5px`,
  });

  const Breadcrumb = g.ul({
    fontSize: `13px`,
    lineHeight: `20px`,
    marginLeft: `0 !important`,
    marginTop: `0`,
    marginBottom: `10px !important`,
    fontWeight: `500`,
    letterSpacing: `.015em`,
  });

  const BreadcrumbItem = g.li({
    display: `inline`,
    "&:first-of-type": {
      "&::before": {
        content: `none`,
      },
    },
    "&::before": {
      content: `/`,
      padding: `0 .5em`,
    },
  });

  const cssBreadcrumbLink = css({
    color: `#3c4d68`,
    borderBottom: `none`,
  });

  const IframeWrapper = g.div({
    padding: `56.25% 0 0 0`,
    position: `relative`,
  });

  const cssIframe = css({
    border: `none`,
    width: `100%`,
    position: `absolute`,
    top: `0`,
    left: `0`,
    height: `100%`,
  });

  const cssButton = css({
    color: `white`,
    backgroundColor: `#3c4d68`,
    border: `1px solid #3c4d68`,
    padding: `.4em 1.5em`,
    display: `block`,
    margin: `1em 0 12px`,
    borderRadius: `5px`,
    textAlign: `center`,
    fontWeight: `500`,
    fontSize: `15px`,
    width: `160px`,
    float: `right`,
    textDecoration: `none`,
    letterSpacing: `.03em`,
    transition: `all 0.3s ease-in-out 0s`,
    ":hover": {
      backgroundColor: `white`,
      color: `#3c4d68`,
      transition: `all 0.3s ease-in-out 0s`,
    },
  });

  return (
    <Layout>
      <g.Div id="page-wrap" margin={`0 auto`} backgroundColor={`#fcfcfc`}>
        <Helmet>
          <title>
            Dr Cédric Kron, un des meilleurs chirurgien esthétique à Paris
          </title>
          <meta
            name="description"
            content="Le Dr Kron figure parmi les meilleurs chirurgien esthétique de Paris. Il est qualifié et reconnu en chirurgie esthétique et plastique, et en médecine esthétique."
          />
          <html lang="fr" />
          <link
            rel="alternate"
            hreflang="en"
            href="https://www.dr-kron.com/en/plastic-surgeon-paris/"
          />
          <meta
            property="og:url"
            content="https://www.dr-kron.com/chirurgien-esthetique-paris/"
          />
          <meta property="og:type" content="article" />
          <meta
            property="og:title"
            content="Dr Cédric Kron, un des meilleurs chirurgien esthétique à Paris"
          />
          <meta
            property="og:description"
            content="Le Dr Kron figure parmi les meilleurs chirurgien esthétique de Paris. Il est qualifié et reconnu en chirurgie esthétique et plastique, et en médecine esthétique."
          />
          <meta
            property="og:image"
            content="https://www.dr-kron.com/dr-cedric-kron-chirurgien-esthetique-paris.jpg"
          />
          <meta property="og:image:type" content="image/jpeg" />
          <meta property="og:image:width" content="1441" />
          <meta property="og:image:height" content="895" />

          <meta property="og:locale" content="fr_FR" />
          <meta property="fb:app_id" content="402235436975681" />
          <script type="application/ld+json">{` {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [{
          "@type": "ListItem",
          "position": 1,
          "name": "Dr Cédric Kron, Chirurgien esthétique reconnu à Paris",
          "item": "https://www.dr-kron.com/chirurgien-esthetique-paris/"
          }]
              }
    `}</script>
        </Helmet>
        <Menu current="qualifications" pathEn="/en/plastic-surgeon-paris/" />
        <Wrapper>
          <Container css={{ padding: `0 15px !important` }}>
            <section>
              <Title>
                Dr Kron, chirurgien esthétique à Paris, reconnu parmi les
                meilleurs de sa spécialité
              </Title>
              <Img
                fluid={portrait.childImageSharp.fluid}
                css={{
                  margin: `1em auto 1.2em`,
                  "@media only screen and (max-width: 600px)": {
                    marginLeft: `calc(50% - 49.9vw)`,
                    marginRight: `calc(50% - 49.9vw)`,
                    marginBottom: `1em`,
                  },
                }}
                alt="Dr Cédric Kron, chirurgien esthétique reconnu à Paris"
              />

              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to="/" {...cssBreadcrumbLink} title="Page d'accueil">
                    <FaHome
                      css={{
                        fontSize: `1.2em`,
                        paddingBottom: `.2em`,
                        transform: `translateY(5px)`,
                      }}
                    />
                  </Link>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  Dr Kron, chirurgien esthétique à Paris, reconnu parmi les
                  meilleurs de sa spécialité
                </BreadcrumbItem>
              </Breadcrumb>

              <p className="intro">
                Vous cherchez un chirurgien esthétique sérieux, consciencieux,
                et dévoué à Paris ? Le Docteur Cédric Kron a été formé par les
                meilleurs chirurgiens esthétiques de Paris. Ses titres et
                diplômes, ses participations scientifiques dans les congrès
                internationaux ainsi que les{" "}
                <Link to="/temoignages-avis-patients/">
                  témoignages de ses patients
                </Link>{" "}
                montrent sa volonté de vous prendre en charge dans la continuité
                de la meilleure façon possible telle que le recommande le
                Conseil de l’Ordre des Médecins (Articles 32 et 33 du Code de
                Déontologie des Médecins).
              </p>
              <p>
                <strong>
                  Ancien Interne{" "}
                  <a
                    href="http://www.aaihp.fr/MedailleAPHP.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Médaille d'Or
                  </a>{" "}
                  de Chirurgie des Hôpitaux de Paris
                </strong>{" "}
                et{" "}
                <strong>
                  Membre associé de l’
                  <a
                    href="http://academie-chirurgie.fr/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Académie Nationale de Chirurgie
                  </a>
                </strong>
                , le Docteur Cédric Kron est qualifié en Chirurgie Plastique,
                Reconstructrice et esthétique, le diplôme officiel reconnu en
                France. Il est inscrit en tant que chirurgien esthétique au{" "}
                <a
                  href="https://www.conseil-national.medecin.fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Conseil de l'Ordre des Médecins
                </a>{" "}
                sous le numéro d'inscription 66193.
              </p>
              <p>
                La{" "}
                <strong>
                  Chirurgie Plastique, Reconstructrice et esthétique
                </strong>{" "}
                est une qualification officielle décernée par le Conseil
                National de l'Ordre des Médecins et atteste de la compétence du
                chirurgien esthétique.
              </p>
              <Row>
                <Col span={{ sm: 12 / 12, md: 9 / 12 }}>
                  <p>
                    Le Docteur Kron exerce son activité de{" "}
                    <strong>
                      médecin esthétique et de chirurgien esthétique à Paris
                    </strong>
                    , exclusivement en secteur privé, ce qui lui permet de
                    prendre en charge ses patients 24h/24 quand cela est
                    nécessaire. Il consulte dans son propre espace de
                    consultation situé à Paris (17ème), un{" "}
                    <Link to="/centre-esthetique-paris/">
                      centre esthétique
                    </Link>{" "}
                    dédié à une{" "}
                    <strong>prise en charge globale et sur mesure</strong> des
                    traitements esthétiques du visage et du corps. Il intervient
                    uniquement dans des cliniques agréées pour la chirurgie
                    esthétique, notamment la Nouvelle Clinique d'Esthétique des
                    Champs-Elysées (Paris 8).
                  </p>
                  <p>
                    Le Docteur Cédric Kron est un chirurgien esthétique{" "}
                    <strong>
                      conventionné avec les régimes d'assurance-maladie
                      obligatoires (secteur&nbsp;2)
                    </strong>
                    . Les actes inscrits à la nomenclature des actes médicaux
                    pris en charge par la sécurité sociale peuvent faire l'objet
                    d'un{" "}
                    <Link to="/remboursement-securite-sociale-chirurgie-esthetique/">
                      remboursement partiel ou total
                    </Link>{" "}
                    par l'assurance maladie (CPAM) et les centres
                    d'assurance-maladie complémentaire (assurances santé privées
                    ou mutuelles santé).
                  </p>
                </Col>
                <Col span={{ sm: 12 / 12, md: 3 / 12 }}>
                  <Img
                    fluid={portraitoperation.childImageSharp.fluid}
                    alt="Opération de chirurgie esthétique réalisée par le Dr Cédric Kron dans une clinique à Paris 8ème"
                  />
                </Col>
              </Row>
            </section>

            <section>
              <Row>
                <Col>
                  <H2>Titres et qualifications</H2>
                  <Img
                    fluid={medailles.childImageSharp.fluid}
                    css={{
                      margin: `1em auto 1.2em`,
                      "@media only screen and (max-width: 600px)": {
                        marginLeft: `calc(50% - 49.9vw)`,
                        marginRight: `calc(50% - 49.9vw)`,
                        marginBottom: `1em`,
                      },
                    }}
                    alt="Titres, médailles et qualifications du Dr Kron, chirurgien esthétique à Paris"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Ul>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Chirurgien Qualifié Spécialiste en Chirurgie Plastique,
                      Reconstructrice & esthétique
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Membre associé de l’Académie Nationale de Chirurgie
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Diplômé du D.E.S.C. de Chirurgie Plastique,
                      Reconstructrice et esthétique
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Ancien Chef de Clinique à la Faculté de Médecine
                      Saint-Antoine de Paris
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Ancien Assistant des{" "}
                      <a
                        href="https://www.aphp.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Assistance Publique - Hôpitaux de Paris (APHP)"
                      >
                        Hôpitaux de Paris
                      </a>{" "}
                      (Service du Professeur M. Mimoun à Hôpital Rothschild)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Ancien Interne Médaille d'Or de Chirurgie des Hôpitaux de
                      Paris (2000). Seuls deux chirurgiens esthétiques et
                      plastiques ont obtenu cette distinction à ce jour.
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Prix de l'Académie Nationale de Chirurgie (2000)
                    </Li>
                  </Ul>
                </Col>
                <Col>
                  <Ul>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Ancien Praticien-Attaché à l'Hôpital Rothschild (Service
                      du Professeur Maurice Mimoun de 2003 à 2007)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Ancien étudiant d'Harvard Medical School - Children
                      Hospital of Boston (1992)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Diplôme Inter-Universitaire de Microchirurgie
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Diplôme Inter-Universitaire de Chirurgie de la main et du
                      membre supérieur
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Diplôme d'Enseignement Approfondi de Sciences
                      Chirurgicales (option Transplantation)
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Maîtrise d'anatomie : certificat d'anatomie générale et
                      certificat d'anatomie vasculaire
                    </Li>
                    <Li>
                      <FaCheckCircle {...cssCheck} />
                      Lauréat de la{" "}
                      <a
                        href="https://www.medecine.parisdescartes.fr/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Faculté de Médecine de Paris
                      </a>
                    </Li>
                  </Ul>
                </Col>
              </Row>
            </section>
            <section>
              <Title2>Son approche de chirurgien esthétique</Title2>
              <Row>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Img
                    fluid={portraitbis.childImageSharp.fluid}
                    css={{ marginBottom: `.7em` }}
                    alt="Portrait du docteur Cédric Kron, chirurgien esthétique exerçant à Paris dans le 17e arrondissement"
                  />
                </Col>
                <Col span={{ sm: 12 / 12, md: 8 / 12 }}>
                  <p>
                    Le Docteur Cédric Kron prend en charge l'ensemble des
                    traitements relevant de la chirurgie esthétique, plastique
                    et reconstructrice et de la médecine esthétique. Les
                    interventions chirurgicales qu'il pratique le plus
                    réguliérement sont celles relevant du rajeunissement du{" "}
                    <Link to="/visage/">visage</Link>, le{" "}
                    <Link to="/visage/lifting-visage-paris/">
                      lifting du visage LVPA
                    </Link>
                    , la <Link to="/nez/rhinoplastie/">rhinoplastie</Link> et la{" "}
                    <Link to="/seins/">chirurgie des seins</Link> (
                    <Link to="/seins/protheses-mammaires/">
                      prothèses mammaires
                    </Link>{" "}
                    et{" "}
                    <Link to="/seins/rajeunissement-mammaire-correction-ptose/">
                      correction de ptôse
                    </Link>
                    ).
                  </p>
                  <p>
                    {" "}
                    Tous les traitements de médecine et de chirurgie esthétique
                    qu'il pratique ont fait{" "}
                    <strong>
                      {" "}
                      preuve de leur sécurité et de leur efficacité
                    </strong>
                    .
                  </p>
                </Col>
              </Row>

              <Row>
                <Col
                  css={{
                    textAlign: `center`,
                    marginTop: `1.5em`,
                    marginBottom: `1em`,
                  }}
                >
                  <Link to="/interventions/" {...ccPictoLink}>
                    <IoIosBody {...cssPicto1} />
                    Traitements pratiqués par zone du corps
                  </Link>
                </Col>
                <Col css={{ textAlign: `center`, marginTop: `1.68em` }}>
                  <Link to="/techniques/" {...ccPictoLink}>
                    <FaUserMd {...cssPicto2} /> Techniques utilisées par le Dr
                    Kron
                  </Link>
                </Col>
              </Row>
            </section>

            <section>
              <h3>Un temps de récupération limité et un résultat naturel</h3>
              <p>
                Dans sa pratique, le Docteur Kron répond aux exigences des
                patients qui souhaitent{" "}
                <strong>
                  combiner les avantages de la chirurgie esthétique et de la
                  Médecine esthétique
                </strong>
                . D'une part, il s'appuie sur les résultats nets et
                indiscutables permis par la chirurgie esthétique. D'autre part,
                il prend en compte les faibles risques et les effets secondaires
                réduits offerts par les traitements de médecine esthétique.
              </p>
              <p>
                Par exemple, les augmentations mammaires par prothèses qu'il
                pratique sont le plus souvent réalisées en ambulatoire et les
                patientes sont capables de se doucher et se mouvoir dès le soir
                de l'intervention. Pour les patientes qui ne souhaitent pas
                avoir recours à une anesthésie générale, le Dr Kron propose des
                opérations de{" "}
                <Link to="/chirurgie-esthetique-sous-anesthesie-locale/">
                  chirurgie esthétique sous anesthésie locale
                </Link>
                , notamment le mini-lift du visage, l’augmentation mammaire
                pré-musculaire ou la liposuccion douce.
              </p>

              <p>
                Il propose ainsi des interventions chirurgicales avec des{" "}
                <strong>temps de récupération limités</strong> et des
                traitements de médecine esthétique avec des{" "}
                <strong>résultats efficaces et durables</strong>. Cette double
                compétence de médecin et de chirurgien esthétique lui permet
                d'associer chirurgie et Médecine dans le cadre d'une même
                intervention pour un résultat optimal et sur mesure. Quel que
                soit l'acte proposé, la priorité est donnée à l'
                <strong>aspect naturel du résultat esthétique</strong> et à
                l'adéquation de la solution thérapeuthique proposée avec les
                souhaits et l'anatomie initiale des patients.
              </p>
              <h3>
                Une prise en charge sur mesure des traitements esthétiques
              </h3>
              <p>
                L'ensemble des interventions de chirurgie esthétique pratiquées
                par le Dr Cédric Kron sont élaborées dans un processus de{" "}
                <strong>prise en charge sur-mesure</strong> avant, pendant et
                après l'intervention.
              </p>
              <p>
                Une prise en charge kinésithérapeutique et/ou ostéopathique
                spécifique et mise au point avec le Dr Kron est parfois proposée
                afin d'accélérer le processus de récupération et la reprise de
                la vie active et/ou sportive.
              </p>
              <p>
                Dans le cadre de cette approche sur mesure de la convalescence
                après des opérations de chirurgie esthétique, le Dr Kron a mis
                en place un protocole de rééducation postopératoire et de
                coaching de récupération ou d'amélioration de la condition
                physique et sportive assistée par EMS (électro-myo-stimulation)
                à l'aide du dispositif{" "}
                <strong>
                  <Link to="/xbody-sculpter-raffermir-son-corps/">XBody</Link>
                </strong>
                .
              </p>
            </section>
            <section>
              <H2>Activités scientifiques</H2>
              <Row>
                <Col span={{ sm: 12 / 12, lg: 6 / 12 }}>
                  <p>
                    Le Dr Cédric Kron est reconnu sur les milieux scientifiques
                    et participe ou organise chaque année plusieurs{" "}
                    <strong>congrès internationaux</strong> et réunions
                    scientifiques avec les meilleurs chirurgiens esthétiques. Il
                    est membre de la Société Française de Chirurgie Plastique,
                    Reconstructrice et Esthétique (
                    <a
                      href="https://www.sofcpre.fr/chirurgiens/dr-kron-cedric.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SoFCPRE
                    </a>
                    ), de la Société Française des Chirurgiens esthétiques
                    Plasticiens (
                    <a
                      href="http://www.chirurgiens-esthetiques-plasticiens.com/membres/cedrickron/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SOFCEP
                    </a>
                    ) et du Syndicat National de Chirurgie Plastique,
                    Reconstructrice et esthétique (
                    <a
                      href="http://www.sncpre.org/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      SNCPRE
                    </a>
                    ).
                  </p>
                  <p>
                    Il est également correspondant et expert scientifique pour
                    des <strong>laboratoires médicaux</strong> français et
                    étrangers, en particulier dans le domaine des produits de
                    comblement et de la recherche sur le vieillissement des
                    tissus.
                  </p>
                </Col>
                <Col span={{ sm: 12 / 12, lg: 6 / 12 }}>
                  <IframeWrapper>
                    <iframe
                      src="https://player.vimeo.com/video/84618487?color=2b6982&amp;title=0&amp;byline=0&amp;portrait=0"
                      title="Reportage vidéo : Science et rajeunissement esthétique"
                      {...cssIframe}
                    />
                  </IframeWrapper>
                  <Link {...cssButton} to="/video-chirurgie-esthetique/">
                    <FaPlay
                      css={{ marginRight: `10px`, marginBottom: `-2px` }}
                    />
                    Toutes les vidéos
                  </Link>
                </Col>
                <Col>
                  <p>
                    Outre ses publications dans le{" "}
                    <strong>domaine scientifique</strong>, le Dr Cédric Kron
                    collabore réguliérement avec la télévision et la{" "}
                    <Link to="/revue-de-presse/">
                      presse généraliste et la presse féminine
                    </Link>{" "}
                    sur le thème de la chirurgie esthétique, du vieillissement
                    et des avancées techniques dans le domaine de la chirurgie
                    esthétique, plastique et reconstructrice ainsi que de la
                    médecine esthétique.
                  </p>
                </Col>
              </Row>
            </section>
            <section>
              <Title2bis>
                Traitements esthétiques conçus par le Dr Kron
              </Title2bis>
              <p className="intro">
                En tant que médecin et chirurgien esthétique à Paris, le Dr
                Cédric Kron pratique l'ensemble des traitements esthétiques
                actuels qui ont fait preuve de leur sécurité et de leur
                efficacité. Il a également mis au point une technique de lifting
                du visage ainsi que deux protocoles combinés de médecine
                esthétiques, l'un pour la suppression des graisses, l'autre pour
                la réjuvénation en profondeur du visage.
              </p>
              <Row>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/visage/lifting-visage-paris/">
                      <Img
                        fluid={lvpa.childImageSharp.fluid}
                        alt="Lifting LVPA, une chirurgie esthétique du visage sans cicatrice"
                      />
                    </Link>
                    <Link to="/visage/lifting-visage-paris/" {...cssLinkH3Block}>
                      <H3Block>Lifting du visage LVPA</H3Block>
                    </Link>
                    <TileText>
                      Le lifting du visage verticaux profonds antérieurs (LVPA)
                      offre des résultats naturels et durables, ainsi qu'une
                      resocialisation rapide en raison des risques très limités
                      d'hématomes et des cicatrices naturellement dissimulées.
                    </TileText>

                    <div>
                      <Link
                        to="/visage/lifting-visage-paris/"
                        title="Lifting du visage LVPA : fiche d'information"
                      >
                        <More>En savoir plus</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/silhouette/keybody-lipo/">
                      <Img
                        fluid={keybodylipo.childImageSharp.fluid}
                        alt="Traitement non invasif de suppression des graisses et de sculpting musculaier par Keybody-Lipo"
                      />
                    </Link>
                    <Link to="/silhouette/keybody-lipo/" {...cssLinkH3Block}>
                      <H3Block>Keybody-Lipo</H3Block>
                    </Link>
                    <TileText>
                      Protocole non invasif et innovant de suppression
                      définitive des graisses et de sculpting musculaire,
                      Keybody-Lipo permet d'obtenir un résultat comparable à une
                      lipoaspiration sans les effets secondaires.
                    </TileText>

                    <div>
                      <Link
                        to="/silhouette/keybody-lipo/"
                        title="Suppression des graisses avec Keybody-Lipo : fiche d'information"
                      >
                        <More>En savoir plus</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
                <Col span={{ sm: 12 / 12, md: 4 / 12 }}>
                  <Tile>
                    <Link to="/peau/keybody-skin/">
                      <Img
                        fluid={keybodyskin.childImageSharp.fluid}
                        alt="Keybody-Skin, un traitement esthétique de réjuvénation de la peau"
                      />
                    </Link>
                    <Link to="/peau/keybody-skin/" {...cssLinkH3Block}>
                      <H3Block>Keybody-Skin</H3Block>
                    </Link>
                    <TileText>
                      Ce protocole combiné s’adresse aux patients qui souhaitent
                      corriger les imperfections de leur peau (cicatrices,
                      taches pigmentaires, pores dilatés) et combattre les
                      effets du vieillissement (rides, perte d’élasticité et de
                      fermeté).
                    </TileText>

                    <div>
                      <Link to="/peau/keybody-skin/" title="Keybody-Skin">
                        <More>En savoir plus</More>
                      </Link>
                    </div>
                  </Tile>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Social
                    path="https://www.dr-kron/chirurgien-esthetique-paris/"
                    title="Dr Cédric Kron - Chirurgien esthetique à Paris"
                  />
                </Col>
              </Row>
            </section>
          </Container>
        </Wrapper>

        <Footer />
      </g.Div>
    </Layout>
  );
}

export const QualificationsQuery = graphql`
  query MyQueryName {
    portrait: file(
      relativePath: {
        eq: "images/dr-cedric-kron-chirurgien-esthetique-paris.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 970) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    medailles: file(
      relativePath: {
        eq: "images/medailles-chirurgien-esthetique-reconnu-dr-kron.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 970) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    lvpa: file(relativePath: { eq: "images/lifting-visage-lvpa.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodylipo: file(
      relativePath: { eq: "images/keybody-lipo-supprimer-graisses.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    keybodyskin: file(relativePath: { eq: "images/keybody-skin.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 306) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitbis: file(
      relativePath: {
        eq: "images/dr-cedric-kron-chirurgie-esthetique-paris.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    portraitoperation: file(
      relativePath: { eq: "images/operation-chirurgie-dr-kron.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`;
